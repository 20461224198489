import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogActions } from "@mui/material";

function ConfirmationDialog(props)
{
	return (
		<Dialog open={props.open}>
			{props.title && <DialogTitle>{props.title}</DialogTitle>}

			{props.content}

			<DialogActions>
				{props.dismissiveAction}
				{props.confirmingAction}
			</DialogActions>
		</Dialog>
	);
}

ConfirmationDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	title: PropTypes.string,
	content: PropTypes.element.isRequired,
	dismissiveAction: PropTypes.element.isRequired,
	confirmingAction: PropTypes.element.isRequired,
};

export default ConfirmationDialog;
