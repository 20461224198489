import camelCase from "camelcase";
import React from "react";

import { createTheme } from "@mui/material/styles";

import firebase, { auth, firestore } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";

import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey as gray,
  blueGrey as blueGray,
} from "@mui/material/colors";

const colors = {
  red: {
    id: "red",
    name: "Red",
    import: red,
  },

  pink: {
    id: "pink",
    name: "Pink",
    import: pink,
  },

  purple: {
    id: "purple",
    name: "Purple",
    import: purple,
  },

  deepPurple: {
    id: "deep-purple",
    name: "Deep Purple",
    import: deepPurple,
  },

  indigo: {
    id: "indigo",
    name: "Indigo",
    import: indigo,
  },

  blue: {
    id: "blue",
    name: "Blue",
    import: blue,
  },

  lightBlue: {
    id: "light-blue",
    name: "Light Blue",
    import: lightBlue,
  },

  cyan: {
    id: "cyan",
    name: "Cyan",
    import: cyan,
  },

  teal: {
    id: "teal",
    name: "Teal",
    import: teal,
  },

  green: {
    id: "green",
    name: "Green",
    import: green,
  },

  lightGreen: {
    id: "light-green",
    name: "Light Green",
    import: lightGreen,
  },

  lime: {
    id: "lime",
    name: "Lime",
    import: lime,
  },

  yellow: {
    id: "yellow",
    name: "Yellow",
    import: yellow,
  },

  amber: {
    id: "amber",
    name: "Amber",
    import: amber,
  },

  orange: {
    id: "orange",
    name: "Orange",
    import: orange,
  },

  deepOrange: {
    id: "deep-orange",
    name: "Deep Orange",
    import: deepOrange,
  },

  brown: {
    id: "brown",
    name: "Brown",
    import: brown,
  },

  gray: {
    id: "gray",
    name: "Gray",
    import: gray,
  },

  blueGray: {
    id: "blue-gray",
    name: "Blue Gray",
    import: blueGray,
  },
};

const getColor = (colorId) => {
  if (!colorId) {
    return null;
  }

  colorId = camelCase(colorId);

  return colors[colorId];
};

const defaultPrimaryColor = getColor(process.env.REACT_APP_THEME_PRIMARY_COLOR);
const defaultSecondaryColor = getColor(
  process.env.REACT_APP_THEME_SECONDARY_COLOR
);
const defaultDark = process.env.REACT_APP_THEME_DARK === "true";

const defaultTheme = createTheme({
  palette: {
    primary: defaultPrimaryColor.import,
    secondary: defaultSecondaryColor.import,
    mode: defaultDark ? "dark" : "light",
  },

  primaryColor: defaultPrimaryColor,
  secondaryColor: defaultSecondaryColor,
  dark: defaultDark,
});

export const appearance = {
  colors,
  defaultTheme,
  defaultPrimaryColor,
  defaultSecondaryColor,
  defaultDark,
  isDefaultTheme(theme) {
    if (
      theme.primaryColor.id === defaultPrimaryColor.id &&
      theme.secondaryColor.id === defaultSecondaryColor.id &&
      theme.dark === defaultDark
    ) {
      return true;
    }

    return false;
  },
  createTheme(theme) {
    if (!theme) {
      return null;
    }

    let primaryColor = theme.primaryColor;
    let secondaryColor = theme.secondaryColor;
    let dark = theme.dark;

    if (!primaryColor || !secondaryColor) {
      return null;
    }

    primaryColor = getColor(primaryColor);
    secondaryColor = getColor(secondaryColor);

    if (!primaryColor || !secondaryColor) {
      return null;
    }

    theme = createTheme({
      palette: {
        primary: primaryColor.import,
        secondary: secondaryColor.import,
        mode: dark ? "dark" : "light",
      },

      primaryColor: primaryColor,
      secondaryColor: secondaryColor,
      dark: dark,
    });

    return theme;
  },
  changeTheme(theme) {
    return new Promise((resolve, reject) => {
      if (!theme) {
        reject(new Error("No theme"));

        return;
      }

      let primaryColor = theme.primaryColor;
      let secondaryColor = theme.secondaryColor;
      let dark = theme.dark;

      if (!primaryColor || !secondaryColor) {
        reject(new Error("No primary color or secondary color"));

        return;
      }

      primaryColor = getColor(primaryColor);
      secondaryColor = getColor(secondaryColor);

      if (!primaryColor || !secondaryColor) {
        reject(new Error("No primary color or secondary color"));

        return;
      }

      const currentUser = auth.currentUser;

      if (!currentUser) {
        reject(new Error("No current user"));

        return;
      }

      const uid = currentUser.uid;

      if (!uid) {
        reject(new Error("No UID"));

        return;
      }

      const userDocumentReference = doc(firestore, "users", uid);

      updateDoc(userDocumentReference, {
          theme: {
            primaryColor: primaryColor.id,
            secondaryColor: secondaryColor.id,
            dark: dark,
          },
        })
        .then((value) => {
          resolve(value);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  changePrimaryColor(primaryColor) {
    return new Promise((resolve, reject) => {
      if (!primaryColor) {
        reject(new Error("No primary color"));

        return;
      }

      primaryColor = getColor(primaryColor);

      if (!primaryColor) {
        reject(new Error("No primary color"));

        return;
      }

      const currentUser = auth.currentUser;

      if (!currentUser) {
        reject(new Error("No current user"));

        return;
      }

      const uid = currentUser.uid;

      if (!uid) {
        reject(new Error("No current user"));

        return;
      }

      const userDocumentReference = doc(firestore, "users", uid);

      updateDoc(userDocumentReference, {
          "theme.primaryColor": primaryColor.id,
        })
        .then((value) => {
          resolve(value);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  changeSecondaryColor(secondaryColor) {
    return new Promise((resolve, reject) => {
      if (!secondaryColor) {
        reject(new Error("No secondary color"));

        return;
      }

      secondaryColor = getColor(secondaryColor);

      if (!secondaryColor) {
        reject(new Error("No secondary color"));

        return;
      }

      const currentUser = auth.currentUser;

      if (!currentUser) {
        reject(new Error("No current user"));

        return;
      }

      const uid = currentUser.uid;

      if (!uid) {
        reject(new Error("No UID"));

        return;
      }

      const userDocumentReference = doc(firestore, "users", uid);

      updateDoc(userDocumentReference, {
          "theme.secondaryColor": secondaryColor.id,
        })
        .then((value) => {
          resolve(value);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  changeDark(dark) {
    return new Promise((resolve, reject) => {
      const currentUser = auth.currentUser;

      if (!currentUser) {
        reject(new Error("No current user"));

        return;
      }

      const uid = currentUser.uid;

      if (!uid) {
        reject(new Error("No UID"));

        return;
      }

      const userDocumentReference = doc(firestore, "users", uid);

      updateDoc(userDocumentReference, {
          "theme.dark": dark,
        })
        .then((value) => {
          resolve(value);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  changeSyncAppearance(syncAppearance) {
    return new Promise((resolve, reject) => {
      const currentUser = auth.currentUser;

      if (!currentUser) {
        reject(new Error("No current user"));

        return;
      }

      const uid = currentUser.uid;

      if (!uid) {
        reject(new Error("No UID"));

        return;
      }

      const userDocumentReference = doc(firestore, "users", uid);

      updateDoc(userDocumentReference, {
          "theme.syncAppearance": syncAppearance,
        })
        .then((value) => {
          resolve(value);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  resetTheme() {
    return new Promise((resolve, reject) => {
      const currentUser = auth.currentUser;

      if (!currentUser) {
        reject(new Error("No current user"));

        return;
      }

      const uid = currentUser.uid;

      if (!uid) {
        reject(new Error("No UID"));

        return;
      }

      const userDocumentReference = doc(firestore, "users", uid);

      updateDoc(userDocumentReference, {
          theme: firebase.firestore.FieldValue.delete(),
        })
        .then((value) => {
          resolve(value);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
};

export const ThemeContext = React.createContext(appearance);
