import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import Loader from "../Loader";
import makeStyles from "@mui/styles/makeStyles";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewPublicacaoDialog from "./ViewPublicacaoDialog";
import clsx from 'clsx';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteForever from '@mui/icons-material/DeleteForever';
import AddTaskIcon from '@mui/icons-material/AddTask';
// import SearchIcon from "@mui/icons-material/Search";
// import ClearIcon from "@mui/icons-material/Clear";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import f_publicacoes from "../../services/f_publicacoes";
import { streamCollection } from "../../services/f_utils";
import ConfirmationDialog from "../ConfirmationDialog";
import { where, or } from "firebase/firestore";
import LazyLoad from 'react-lazyload';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';

import {
	Grid,
	Paper,
	Fab,
	Box,
	Typography,
	Chip,
	Checkbox,
	TextField,
	InputLabel,
	Button,
	Select,
	MenuItem,
	FormControl,
	ListItemText,
	// InputAdornment,
	// IconButton,
	Tooltip
} from "@mui/material";

const useStyles = makeStyles({
	paperList: {
		width: '100%',
		textAlign: "center",
		padding: '1vmin'
	},
	bgPendente: {
		background: 'linear-gradient(45deg, rgba(242, 22, 59, 0.3) 30%, rgba(255, 0, 0, 0.25) 90%)',
		boxShadow: '0px 0px 3px 2px rgba(255, 38, 0, .2)',
	},
	bgProcessado: {
		background: 'linear-gradient(45deg, rgba(66, 237, 143, 0.3) 30%, rgba(0, 255, 4, 0.25) 90%)',
		boxShadow: '0px 0px 5px 2px rgba(0, 235, 4, .4)',
	},
	bgAguardando: {
		background: 'linear-gradient(45deg, rgba(235, 235, 38, 0.3) 30%, rgba(0, 255, 4, 0.25) 90%)',
		boxShadow: '0px 0px 5px 2px rgba(0, 235, 4, .4)',
	},
	search: {
		margin: "0"
	}
});

function PublicacoesPage(props) {
	const [selectedPublicacaoIdx, setselectedPublicacaoIdx] = useState(undefined);
	const [publicacoesList, setPublicacoesList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [dialogPublicacaoOpen, setDialogPublicacaoOpen] = useState(false);
	const [visualizarIgnorados, setVisualizarIgnorados] = useState(false); 
	const [visualizarAguardandoProcessamento, setVisualizarAguardandoProcessamento] = useState(false);
	const [visualizarPendentes, setVisualizarPendentes] = useState(true); 
	const [visualizarProcessados, setVisualizarProcessados] = useState(false);
	const [motivosInclusaoList, setMotivosInclusaoList] = React.useState(['CONSIDERADO COMO DUPLICADO', 'PROCESSO NÃO ENCONTRADO', 'PROCESSO ARQUIVADO']);
	const [usuarioPortalList, setUsuarioPortalList] = useState([
		{text: 'Barella 82001 - Cível', value: '82001'},
		{text: 'Ana Laura 1061166 - Cível', value: '1061166'}, 
		{text: 'Carina 133813 - Cível', value: '133813'}, 
		{text: 'Carina cmendonca@tmb.adv.br - Cível', value: 'cmendonca@tmb.adv.br'}, 
		{text: 'Thonon 133762 - Cível', value: '133762'}, 
		{text: 'Thonon lthonon@thonon.adv.br - Cível', value: 'lthonon@thonon.adv.br'},
		{text: 'Luiz Henrique 131447 - Cível', value: '131447'}, 
		{text: 'Pedro 1082564 - Cível', value: '1082564'},
		{text: 'Flávio 96943 - Trabalhista', value: '96943'},
		{text: 'Flávio administrativo2@tmb.adv.br - Trabalhista', value: 'administrativo2@tmb.adv.br'},
		{text: 'Maurício 1045486 - Trabalhista', value: '1045486'}
	]);
	const [selectedFiltroMotivoInclusao, setSelectedFiltroMotivoInclusao] = useState("");
	const [selectedFiltroUsuario, setSelectedFiltroUsuario] = useState([]);
	const [checkedStatePubs, setCheckedStatePubs] = useState([]);
	const [dialogDeletePubsOpen, setDialogDeletePubsOpen] = useState(false);
	const [startSelectedDate, setStartSelectedDate] = useState(dayjs());
	const [endSelectedDate, setEndSelectedDate] = useState(dayjs());
	// const [showClearIcon, setShowClearIcon] = useState("none");
	// const [searchInput, setSearchInput] = useState("");
	const classes = useStyles();

	// const handleChangeSearchInput = (event) => {
	// 	if (event.target.value === "") {
	// 		handleClickSearch();
	// 		setShowClearIcon("none");
	// 	} else {
	// 		setShowClearIcon("flex");
	// 	}

	// 	setSearchInput(event.target.value);
	// };

	// const handleClickClearSearch = () => {
	// 	setSearchInput("");
	// 	setShowClearIcon("none");
	// };

	// const handleClickSearch = () => {
	// 	setLoading(true);
	// };

	const handleSetSlectedUsuario = (event) => {
		const {
		  target: { value },
		} = event;
		setSelectedFiltroUsuario(
		  typeof value === 'string' ? value.split(',') : value,
		);
	};

	const handleMarcarTodos = () => {
		setCheckedStatePubs(new Array(publicacoesList.length).fill(true));
		if (checkedStatePubs.every((elem) => elem === true)) 
		{
			setCheckedStatePubs(new Array(publicacoesList.length).fill(false));
		}
	}

	const handleClickDeletePubs = () => {
		handleOpenCloseDialogPublicacao(false, undefined);
		setDialogDeletePubsOpen(false);
		for (let [idx, checkState] of checkedStatePubs.entries()) {
			if (checkState === true) {
				let pubData = publicacoesList[idx];
				f_publicacoes.update(pubData.doc_id, {
					status: "ignorada"
				});
			}
		}
		props.openSnackbar(`Publicações ignoradas.`);
	};

	const handleOpenCloseDialogPublicacao = (state, rpaIdx) => {
		setselectedPublicacaoIdx(rpaIdx);
		setDialogPublicacaoOpen(state);
	}

	const handleSetCheckbox = (idx) => {
		var tmp_checkstate = Object.assign([], checkedStatePubs);
		tmp_checkstate[idx] = !tmp_checkstate[idx];
		setCheckedStatePubs(tmp_checkstate);
	}

	useEffect(() => {
		setLoading(true);
		let list_status = [];
		let list_motivos = [];
		let list_usuarios = [];

		if (visualizarPendentes === true) {
			list_status.push('pendente')
		} else {
			list_status.filter((x) => x !== 'pendente');
		}

		if (visualizarAguardandoProcessamento === true) {
			list_status.push('aguardando_processamento')
		} else {
			list_status.filter((x) => x !== 'aguardando_processamento');
		}

		if (visualizarIgnorados === true) {
			list_status.push('ignorada')
		} else {
			list_status.filter((x) => x !== 'ignorada');
		}

		if (visualizarProcessados === true) {
			list_status.push('processada')
		} else {
			list_status.filter((x) => x !== 'processada');
		}

		if (selectedFiltroMotivoInclusao !== "") {
			list_motivos.push(selectedFiltroMotivoInclusao)
		} else {
			list_motivos = [];
		}

		if (selectedFiltroUsuario.length > 0) {
			list_usuarios = selectedFiltroUsuario;
		} else {
			list_usuarios = [];
		}

		let filter_status = undefined;
		let filter_motivos = undefined;
		let filter_usuarios = undefined;
		
		let filter_dateStart = or(where("data_publicacao", ">=", startSelectedDate.toDate()), where("data_disponibilizacao", ">=", startSelectedDate.toDate()));
		let filter_dateEnd = or(where("data_publicacao", "<=", endSelectedDate.toDate()), where("data_disponibilizacao", "<=", endSelectedDate.toDate()));

		if (list_status.length > 0) {
			filter_status = where("status", "in", list_status)
		}
		else 
		{
			filter_status = where("status", "==", "")
		}

		if (list_motivos.length > 0) {
			filter_motivos = where("motivo_inclusao", "in", list_motivos);
		}

		if (list_usuarios.length > 0) {
			filter_usuarios = where("usuario_portal", "in", list_usuarios);
		}

		const unsubscribe = streamCollection('publicacoes', [filter_status, filter_motivos, filter_usuarios, filter_dateStart, filter_dateEnd], (querySnapshot) => {
			const pubsData = querySnapshot.docs.map((docSnapshot) => {
				let _tmp_data = { doc_id: docSnapshot.id, ...docSnapshot.data() }
				return _tmp_data;
			});

			setPublicacoesList(pubsData);
			setCheckedStatePubs(new Array(pubsData.length).fill(false))
			setLoading(false);
		},
			(error) => { console.log(error); }
		);

		console.log('update PublicacoesPage');
		return unsubscribe;
	}, [visualizarIgnorados, visualizarProcessados, selectedFiltroMotivoInclusao, selectedFiltroUsuario, visualizarAguardandoProcessamento, visualizarPendentes, startSelectedDate, endSelectedDate]);

	let elements_publicacoes = [];
	if (publicacoesList !== null && publicacoesList !== undefined && typeof (publicacoesList) === "object") {
		for (let [idx, pubData] of publicacoesList.entries()) {
			if (pubData.motivo_inclusao === undefined || pubData.motivo_inclusao == null) {
				pubData.motivo_inclusao = "CONSIDERADO COMO DUPLICADO"
			}

			// if (motivosInclusaoList.includes(pubData.motivo_inclusao) === false) {
			// 	motivosInclusaoList.push(pubData.motivo_inclusao);
			// }

			// if (usuarioPortalList.includes(pubData.usuario_portal) === false) {
			// 	usuarioPortalList.push(pubData.usuario_portal);
			// }

			elements_publicacoes.push(
				<Grid item key={pubData.num_processo + idx} xs={12} sm={12} md={12} sx={{ ml: '1vmin', mr: '1vmin' }}>
					<LazyLoad>
						<Paper elevation={4} square={true} className={clsx(classes.paperList, { 
							[classes.bgPendente]: pubData.status === null || pubData.status === "pendente",
							[classes.bgAguardando]: pubData.status === "aguardando_processamento",
							[classes.bgProcessado]: pubData.status === "processada"
						})}>
							<Grid container alignItems="center" spacing={0}>
								<Grid item xs={1}>
									<Checkbox checked={checkedStatePubs[idx]} onChange={() => handleSetCheckbox(idx)}/>
								</Grid>

								<Grid item xs={10}>
									<Tooltip title={pubData.status === "aguardando_processamento" ? "Publicação analisada e aguardando processamento." : pubData.status === "pendente" ? "Publicação aguardando análise." : pubData.status === "ignorada" ? "Publicação ignorada." : "Publicação processada."} followCursor>
										<Grid container justifyContent="start" alignItems="center" spacing={1}>
											<Grid item>
												<Typography variant="subtitle2" component="div" sx={{ textTransform: 'uppercase' }}>
													<Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
														PROCESSO: <Chip sx={{ fontWeight: 'normal' }} label={pubData.num_processo} size="small" variant="outlined" />
													</Box>
												</Typography>
											</Grid>

											<Grid item>
												<Typography variant="subtitle2" component="div" sx={{ textTransform: 'uppercase' }}>
													<Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
														PORTAL: <Chip sx={{ fontWeight: 'normal' }} label={pubData.portal} size="small" variant="outlined" />
													</Box>
												</Typography>
											</Grid>

											<Grid item>
												<Typography variant="subtitle2" component="div" sx={{ textTransform: 'uppercase' }}>
													<Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
														DATA PUBLICAÇÃO: <Chip sx={{ fontWeight: 'normal' }} label={pubData.data_publicacao.toDate().toLocaleDateString('pt-BR')} size="small" variant="outlined" />
													</Box>
												</Typography>
											</Grid>

											<Grid item>
												<Typography variant="subtitle2" component="div" sx={{ textTransform: 'uppercase' }}>
													<Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
														DATA DISPONIBILIZAÇÃO: <Chip sx={{ fontWeight: 'normal' }} label={pubData.data_disponibilizacao.toDate().toLocaleDateString('pt-BR')} size="small" variant="outlined" />
													</Box>
												</Typography>
											</Grid>

											<Grid item>
												<Typography variant="subtitle2" component="div" sx={{ textTransform: 'uppercase' }}>
													<Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
														MOTIVO INCLUSÃO: <Chip sx={{ fontWeight: 'normal' }} label={pubData.motivo_inclusao} size="small" variant="outlined" />
													</Box>
												</Typography>
											</Grid>

											<Grid item>
												<Typography variant="subtitle2" component="div" sx={{ textTransform: 'uppercase' }}>
													<Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
														RECURSO: <Chip sx={{ fontWeight: 'normal' }} label={pubData.nome_recurso || "N/A"} size="small" variant="outlined" />
													</Box>
												</Typography>
											</Grid>

											<Grid item>
												<Typography variant="subtitle2" component="div" sx={{ textTransform: 'uppercase' }}>
													<Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
														USUÁRIO: <Chip sx={{ fontWeight: 'normal' }} label={pubData.usuario_portal || "N/A"} size="small" variant="outlined" />
													</Box>
												</Typography>
											</Grid>
										</Grid>
									</Tooltip>
								</Grid>

								<Grid item xs={1}>
									<Grid container justifyContent="end" alignItems="center" spacing={1}>
										<Grid item>
											<Tooltip title="Visualizar publicação" arrow>
												<Fab size="small" color="secondary" aria-label="edit" >
													<VisibilityIcon onClick={() => handleOpenCloseDialogPublicacao(true, idx)} />
												</Fab>
											</Tooltip>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</LazyLoad>
				</Grid>
			);
		}
	}

	return (
		<React.Fragment>

			<ViewPublicacaoDialog
				open={dialogPublicacaoOpen}
				handleClose={handleOpenCloseDialogPublicacao}
				openSnackbar={props.openSnackbar}
				publicacaoData={publicacoesList[selectedPublicacaoIdx]}
				motivosInclusaoList={motivosInclusaoList}
			/>

			<ConfirmationDialog
				open={dialogDeletePubsOpen}
				onClose={setDialogDeletePubsOpen}
				content={<Typography variant="subtitle1" component="div" sx={{ textTransform: 'uppercase', m: 1 }}>Deseja ignorar as publicações selecionadas ?</Typography>}
				dismissiveAction={<Button color="primary" onClick={() => setDialogDeletePubsOpen(false)}>Cancelar</Button>}
				confirmingAction={<Button color="warning" onClick={() => handleClickDeletePubs()}>Sim</Button>}
			/>

			<Grid container justifyContent="center" alignItems="center" spacing={2}>
				{/* SEARCH INPUT */}
				{/* <Grid item xs={12} sm={12} sx={{ mt: 2, textAlign: 'center' }}>
					<Grid container justifyContent="center" alignItems="center" spacing={2}>
						<Grid item xs={6} sm={6}>
							<Box sx={{ textAlign: 'center' }}>
								<FormControl className={classes.search}>
									<TextField
										size="small"
										variant="outlined"
										onChange={handleChangeSearchInput}
										value={searchInput}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start" onClick={handleClickSearch}>
													{showClearIcon ?
														<IconButton onClick={() => handleClickSearch()} sx={{ m: 0, p: 0 }}>
															<SearchIcon />
														</IconButton>
														:
														<SearchIcon />
													}
												</InputAdornment>
											),
											endAdornment: (
												<InputAdornment
													position="end"
													style={{ display: showClearIcon }}
												>
													<IconButton onClick={() => handleClickClearSearch()}>
														<ClearIcon />
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</FormControl>
							</Box>
						</Grid>
					</Grid>
				</Grid> */}

				<Grid item xs={12} sm={12} sx={{ mt: 2, textAlign: 'center' }}>
					<Grid container justifyContent="center" alignItems="center" spacing={2}>
						<Grid item>
							<Fab variant="extended" onClick={() => setVisualizarPendentes(!visualizarPendentes)} className={clsx({
								[classes.bgProcessado]: visualizarPendentes === true
							})}>
								{visualizarPendentes === false ?
									<VisibilityIcon sx={{ mr: 1 }} />
									:
									<VisibilityOffIcon sx={{ mr: 1 }} />
								}
								Visualizar pendentes
							</Fab>
						</Grid>
						<Grid item>
							<Fab variant="extended" onClick={() => setVisualizarAguardandoProcessamento(!visualizarAguardandoProcessamento)} className={clsx({
								[classes.bgProcessado]: visualizarAguardandoProcessamento === true
							})}>
								{visualizarAguardandoProcessamento === false ?
									<VisibilityIcon sx={{ mr: 1 }} />
									:
									<VisibilityOffIcon sx={{ mr: 1 }} />
								}
								Visualizar ag. processamento
							</Fab>
						</Grid>
						<Grid item>
							<Fab variant="extended" onClick={() => setVisualizarIgnorados(!visualizarIgnorados)} className={clsx({
								[classes.bgProcessado]: visualizarIgnorados === true
							})}>
								{visualizarIgnorados === false ?
									<VisibilityIcon sx={{ mr: 1 }} />
									:
									<VisibilityOffIcon sx={{ mr: 1 }} />
								}
								Visualizar ignorados
							</Fab>
						</Grid>
						<Grid item>
							<Fab variant="extended" onClick={() => setVisualizarProcessados(!visualizarProcessados)} className={clsx({
								[classes.bgProcessado]: visualizarProcessados === true
							})}>
								{visualizarProcessados === false ?
									<VisibilityIcon sx={{ mr: 1 }} />
									:
									<VisibilityOffIcon sx={{ mr: 1 }} />
								}
								Visualizar processados
							</Fab>
						</Grid>
						<Grid item>
							<FormControl sx={{ minWidth: 220 }}>
								<InputLabel>Filtro - Motivo Inclusão</InputLabel>
								<Select
									value={selectedFiltroMotivoInclusao}
									onChange={(event) => setSelectedFiltroMotivoInclusao(event.target.value)}
									fullWidth={true}
									label="Filtro - Motivo Inclusão"
								>
									<MenuItem value="">
										<em>Nenhum</em>
									</MenuItem>
									{motivosInclusaoList.map((motivo) => { return <MenuItem key={motivo} value={motivo}>{motivo}</MenuItem> })}
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							<FormControl sx={{ minWidth: 240 }}>
								<InputLabel>Filtro - Usuário/Advogado</InputLabel>
								<Select
									multiple
									value={selectedFiltroUsuario}
									renderValue={(selected) => selected.join(', ')}
									onChange={handleSetSlectedUsuario}
									fullWidth={true}
									label="Filtro - Usuário/Advogado"
								>
									{usuarioPortalList.map((usuario) => { return <MenuItem key={usuario.text} value={usuario.value}>
										<Checkbox checked={selectedFiltroUsuario.includes(usuario.value)} />
										<ListItemText primary={usuario.text} />
									</MenuItem> 
									})}
								</Select>
							</FormControl>
						</Grid>

						<Grid item>
							<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
								<Grid container justifyContent="center" alignItems="center" spacing={2}>
									<Grid item>
										<FormControl sx={{ minWidth: 100 }}>
											<DatePicker
												label="Filtro - Data Inicial"
												value={startSelectedDate}
												onChange={(newValue) => setStartSelectedDate(newValue)}
												renderInput={(params) => <TextField size="small" {...params} />}
												views={['year', 'month', 'day']}
											/>
										</FormControl>
									</Grid>
									<Grid item>
										<FormControl sx={{ minWidth: 100 }}>
											<DatePicker
												label="Filtro - Data Final"
												value={endSelectedDate}
												onChange={(newValue) => setEndSelectedDate(newValue)}
												renderInput={(params) => <TextField size="small" {...params} />}
												views={['year', 'month', 'day']}
											/>
										</FormControl>
									</Grid>
								</Grid>
							</LocalizationProvider>
						</Grid>
					</Grid>
				</Grid>
				
				{elements_publicacoes.length > 0 &&
					<Grid item xs={12} sm={12} sx={{ ml: 2, textAlign: 'center' , mt: 2}}>
						<Grid container justifyContent="center" alignItems="center" spacing={2}>
							<Grid item>
								<Fab variant="extended" onClick={() => handleMarcarTodos()}>
									<AddTaskIcon sx={{ mr: 1 }} />
									Marcar todos
								</Fab>
							</Grid>

							{checkedStatePubs.some((elem) => elem === true) &&
								<React.Fragment>
									<Grid item>
										<Fab color="warning" variant="extended" onClick={() => setDialogDeletePubsOpen(true)}>
											<DeleteForever sx={{ mr: 1 }} />
											Ignorar selecionados
										</Fab>
									</Grid>

									<Grid item>
										<Typography>
											({(checkedStatePubs.filter((elem) => elem === true)).length}) Items selecionado(s)
										</Typography>
									</Grid>
								</React.Fragment>
							}

						</Grid>
					</Grid>
				}

				{/* LISTA PUBLICAÇÕES */}
				<Grid item xs={12} sm={12}>
					{(loading === true) ?
						< Loader />
						:
						<Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ p: 1, mt: 3 }}>
							{elements_publicacoes.length > 0 ? elements_publicacoes : "Nenhuma publicação para análise."}
						</Grid>
					}
				</Grid>
			</Grid>
		</React.Fragment>
	);

}

PublicacoesPage.propTypes = {
	user: PropTypes.object.isRequired,
	openSnackbar: PropTypes.func.isRequired,
	roles: PropTypes.array.isRequired,
};

export default PublicacoesPage;
