import React, { Component } from "react";

import PropTypes from "prop-types";

import validate from "validate.js";

import {
	Container,
	Box,
	Grid,
	Button,
	Divider,
	TextField,
	Avatar,
	Link
} from "@mui/material";

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import constraints from "../../data/constraints";
import authentication from "../../services/authentication";

const initialState = {
	performingAction: false,
	emailAddress: "",
	password: "",
	errors: null,
};

class LoginPage extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
	}

	resetPassword = () => {
		const { emailAddress } = this.state;

		const errors = validate(
			{
				emailAddress: emailAddress,
			},
			{
				emailAddress: constraints.emailAddress,
			}
		);

		if (errors) {
			this.setState({
				errors: errors,
			});
		} else {
			this.setState(
				{
					errors: null,
				},
				() => {
					this.setState(
						{
							performingAction: true,
						},
						() => {
							authentication
								.resetPassword(emailAddress)
								.then((value) => {
									this.props.openSnackbar(
										`Sent password reset e-mail to ${emailAddress}`
									);
								})
								.catch((reason) => {
									this.setState({
										performingAction: false,
									});

									const code = reason.code;
									// const message = reason.message;

									switch (code) {
										case "auth/invalid-email":
										case "auth/missing-android-pkg-name":
										case "auth/missing-continue-uri":
										case "auth/missing-ios-bundle-id":
										case "auth/invalid-continue-uri":
										case "auth/unauthorized-continue-uri":
										case "auth/user-not-found":
											this.props.openSnackbar("Login inválido.");
											return;

										default:
											this.props.openSnackbar("Login inválido. (2)");
											return;
									}
								})
						}
					);
				}
			);
		}
	};

	signIn = () => {
		const { emailAddress, password } = this.state;

		const errors = validate(
			{
				emailAddress: emailAddress,
				password: password,
			},
			{
				emailAddress: constraints.emailAddress,
				password: constraints.password,
			}
		);

		if (errors) {
			this.setState({
				errors: errors,
			});
		} else {
			this.setState(
				{
					performingAction: true,
					errors: null,
				},
				() => {
					authentication
						.signIn(emailAddress, password)
						.then((user) => {
							const displayName = user.displayName;
							const emailAddress = user.email;

							this.props.openSnackbar(
								`Logado com ${displayName || emailAddress}`
							);
						})
						.catch((reason) => {
							this.setState({
								performingAction: false,
							});

							const code = reason.code;
							// const message = reason.message;

							switch (code) {
								case "auth/invalid-email":
								case "auth/user-disabled":
								case "auth/user-not-found":
								case "auth/wrong-password":
									this.props.openSnackbar("Login inválido.");
									return;

								case "auth/too-many-requests":
									this.props.openSnackbar("A conta foi temporariamente bloqueada por muitas tentativas de acesso.");
									return;

								default:
									this.props.openSnackbar("Login inválido. (2)");
									return;
							}
						})
				}
			);
		}
	};

	handleKeyPress = (event) => {
		const { emailAddress, password } = this.state;

		if (!emailAddress && !password) {
			return;
		}

		const key = event.key;

		if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
			return;
		}

		if (key === "Enter") {
			this.signIn();
		}
	};

	handleExited = () => {
		this.setState(initialState);
	};

	handleEmailAddressChange = (event) => {
		const emailAddress = event.target.value;

		this.setState({
			emailAddress: emailAddress,
		});
	};

	handlePasswordChange = (event) => {
		const password = event.target.value;

		this.setState({
			password: password,
		});
	};


	render() {
		const { performingAction, emailAddress, password, errors } = this.state;

		return (
			<Container component="main" maxWidth="xs">
				<Box
					sx={{
						marginTop: '50%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: 'secondary.secondary' }}>
						<LockOutlinedIcon />
					</Avatar>

					<Box component="form" noValidate sx={{ mt: 1 }}>
						<TextField
							autoComplete="email"
							disabled={performingAction}
							error={!!(errors && errors.emailAddress)}
							fullWidth
							margin="normal"
							helperText={
								errors && errors.emailAddress
									? errors.emailAddress[0]
									: ""
							}
							label="E-mail"
							placeholder="joe@vexia.com.br"
							required
							type="email"
							value={emailAddress}
							variant="outlined"
							InputLabelProps={{ required: false }}
							onChange={this.handleEmailAddressChange}
						/>

						<TextField
							autoComplete="current-password"
							disabled={performingAction}
							error={!!(errors && errors.password)}
							fullWidth
							helperText={
								errors && errors.password ? errors.password[0] : ""
							}
							label="Senha"
							placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
							required
							type="password"
							value={password}
							variant="outlined"
							InputLabelProps={{ required: false }}
							onChange={this.handlePasswordChange}
						/>

						<Divider
							orientation="horizontal"
							sx={{ margin: "auto", width: "1px" }}
						/>

						<Button
							sx={{ mt: 3, mb: 2 }}
							fullWidth
							color="primary"
							disabled={!emailAddress || performingAction || !password}
							variant="contained"
							onClick={() => this.signIn()}
						>
							Entrar
						</Button>

					</Box>

					<Grid container>
						<Grid item xs>
							<Link href="#"
								color="primary"
								disabled={!emailAddress || performingAction}
								variant="outlined"
								onClick={this.resetPassword}
							>
								Recuperar senha
							</Link>
						</Grid>
					</Grid>
				</Box>
			</Container>
		);
	}
}

LoginPage.propTypes = {
	// Custom Functions
	openSnackbar: PropTypes.func.isRequired,
};

export default LoginPage;
