import React from "react";
import PropTypes from "prop-types";

import { Link as RouterLink } from "react-router-dom";

import {
	AppBar,
	Toolbar,
	Box,
	Button,
	IconButton,
	Divider,
	Menu,
	MenuItem
} from "@mui/material";

import UserAvatar from "../UserAvatar";

function Bar(props) {
	const [anchorAccountEl, setAnchorAccountEl] = React.useState(null);

	const openAccountMenu = (event) => {
		setAnchorAccountEl(event.currentTarget);
	};

	const closeAccountMenu = () => {
		setAnchorAccountEl(null);
	};

	const accountMenuItems = [
		{
			name: "Perfil",
			onClick: props.onSettingsClick,
		},
		{
			name: "Deslogar",
			divide: true,
			onClick: props.onSignOutClick,
		},
	];

	let accountMenuItemsList = [];
	for(let [index, menuItem] of accountMenuItems.entries()) {
		if (menuItem.hasOwnProperty("condition") && !menuItem.condition)
			continue;

		let component = undefined;
		if (menuItem.to) {
			component = (
				<MenuItem
					key={index+menuItem.name}
					component={RouterLink}
					to={menuItem.to}
					onClick={closeAccountMenu}
				>
					{menuItem.name}
				</MenuItem>
			);
		} else {
			component = (
				<MenuItem
					key={index+menuItem.name}
					onClick={() => {
						closeAccountMenu();
						menuItem.onClick();
					}}
				>
					{menuItem.name}
				</MenuItem>
			);
		}

		if (menuItem.divide) {
			accountMenuItemsList.push(
				<span key={index+menuItem.name}>
					<Divider />
					{component}
				</span>
			);
		} else {
			accountMenuItemsList.push(component);
		}
	}

	if (props.user === undefined) {
		return (<React.Fragment></React.Fragment>);
	}

	return (
		<React.Fragment>
			<AppBar color="primary" position="static">
				<Toolbar>
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
						<Button
							color="inherit"
							component={RouterLink}
							to="/"
							variant="outlined"
							sx={{ mr: 1 }}
						>
							PUBLICAÇÕES
						</Button>
					</Box>

					<Box sx={{ flexGrow: 0 }}>
						<IconButton
							color="inherit"
							disabled={props.performingAction}
							onClick={openAccountMenu}
							size="large"
						>
							<UserAvatar user={Object.assign(props.user, props.userData)} />
						</IconButton>
					</Box>

					<Menu
						anchorEl={anchorAccountEl}
						open={Boolean(anchorAccountEl)}
						onClose={closeAccountMenu}
					>
						{accountMenuItemsList}
					</Menu>
				</Toolbar>
			</AppBar>
		</React.Fragment>
	);
}

Bar.defaultProps = {
	performingAction: false,
};

Bar.propTypes = {
	// Properties
	performingAction: PropTypes.bool.isRequired,
	user: PropTypes.object,
	userData: PropTypes.object,

	// Events
	onSettingsClick: PropTypes.func.isRequired,
	onSignOutClick: PropTypes.func.isRequired,
};

export default Bar;
